#--------------------------------------------------------------------------------
# MAPS FUNCTIONS
#--------------------------------------------------------------------------------

# Generate initial position
get_default_position = () ->
  {
    lat: (window.authority_position && window.authority_position.lat) || "0",
    lng: (window.authority_position && window.authority_position.lng) || "0"
  }

# Generate initial position
async_get_position = (name, position, callback=null) ->
  geocodes_list = get_geocodes()
  if (!geocodes_list || !geocodes_list[name])
    if(position && position.attr('data-location-latitude') && position.attr('data-location-longitude'))
      position = get_default_position()
      position.lat = (position && position.attr('data-location-latitude'))  || position.lat;
      position.lng = (position && position.attr('data-location-longitude')) || position.lat;
      geocodes_list[name] = position;
    else
      address = [name]
      address.unshift(window.current_city_area)  if window.current_city_area
      address.unshift(window.current_authority)  if window.current_authority
      address.unshift(window.current_country)    if window.current_country
      address = address.join(', ');
      get_geocode(address, name, callback);
  geocodes_list[name] && callback && callback(geocodes_list[name]);

# Init maps
window.init_map_answer = (containers)->
  ids = []
  containers.each ()->
    # CREATE STRUCT FOR EACH MAP CONTAINER
    current = $(this)
    if current.attr('data-initialized') != 'true'
      current.attr('data-initialized', 'true')
      current_id = current.attr('data-target')
      target = $(".location-raw-value[data-target=#{current_id}]")
      ids.push(current_id)
      if !window.maps[current_id]
        # PARSE POSITIONS DATA
        try
          positions = target.val()
          positions = JSON.parse(positions)
        catch e
          positions = { '0': get_default_position() }
        markers = {}
        center = null
        # CREATE MAP & CALCULATE CENTER
        for name, position of positions
          if position && position.lat && position.lng
            center ||= new google.maps.LatLng(position.lat, position.lng)
        map_options = { zoom: 14, center: center, scrollwheel: false }
        view = new google.maps.Map(current['0'], map_options)
        # CREATE MARKERS FOR MAP
        for name, position of positions
          if position && position.lat && position.lng
            position = new google.maps.LatLng(position.lat, position.lng)
            marker = new google.maps.Marker({
              position: position,
              map: view,
              label: name,
              draggable: true
            })
            google.maps.event.addListener(marker, "mouseup", (e) ->
              refresh_markers([ current_id ])
            )
            markers[name] = marker
        # INCLUDE NEW MAP INFORMATION
        window.maps[current_id] = { view: view, markers: markers }
        # ADD RESIZE EVENT
        google.maps.event.addDomListener(window, 'resize', () ->
          refresh_maps([ current_id ])
        )
  refresh_maps(ids) if ids.length > 0

# Refresh all maps
window.refresh_all_maps = () ->
  setTimeout(()->
    refresh_maps( Object.keys( window.maps ) )
  , 100)

# Refresh single map markers
refresh_markers = (maps_ids) ->
  if maps_ids && maps_ids.length > 0
    setTimeout(()->
      for map_id in maps_ids
        positions = {}
        map_instance = window.maps[map_id]
        bounds = new google.maps.LatLngBounds();
        positions_list = []
        current_raw = $(".location-raw-value[data-target='#{map_id}']")
        try
          positions = current_raw.val()
          positions = JSON.parse(positions)
        catch e
          positions = { }
        if(map_instance)
          for name, marker of map_instance.markers
            positions[name] ||= {}
            positions[name]["lat"] = marker.position.lat()
            positions[name]["lng"] = marker.position.lng()
            bounds.extend(marker.position)
            positions_list.push(marker.position)
        current_raw.val( JSON.stringify( positions ) ) if (current_raw.length > 0)
        google.maps.event.trigger(map_instance.view, 'resize')
        if positions_list.length > 0
          if positions_list.length > 1
            map_instance.view.setCenter(bounds.getCenter())
            map_instance.view.fitBounds(bounds)
          else
            map_instance.view.setCenter(positions_list[0])
        else
          authority_position = get_default_position()
          authority_position = new google.maps.LatLng(authority_position.lat, authority_position.lng)
          map_instance.view.setCenter(authority_position)
    , 300)

# Refresh location map for a given map_ids. It refresh the markers and the view of the map.
refresh_maps = (maps_ids) ->
  if maps_ids && maps_ids.length > 0
    setTimeout(()->
      for map_id in maps_ids
        target = $(".location-raw-value[data-target='#{map_id}']")
        try
          data = target.val()
          data = JSON.parse(data)
        catch e
          data = { '0': get_default_position() }
        map_instance = window.maps[map_id]
        if(map_instance)
          bounds = new google.maps.LatLngBounds();
          positions_list = []
          for name, position of data
            if (position && position.lat && position.lng)
              # When the location does not have a label we remove the markers for it
              if map_instance.markers[name] && Object.hasOwn(position, 'label') && !position.label.length > 0
                map_instance.markers[name].setPosition(null)
              else
                position = new google.maps.LatLng(position.lat, position.lng)
                bounds.extend(position)
                positions_list.push(position)
                if (map_instance.markers[name])
                  map_instance.markers[name].setPosition(position)
                else
                  marker = new google.maps.Marker({
                    position: position,
                    map: map_instance.view,
                    label: name,
                    draggable: true
                  })
                  google.maps.event.addListener(marker, "mouseup", (e) ->
                    refresh_markers([ map_id ])
                  )
                  map_instance.markers[name] = marker
          google.maps.event.trigger(map_instance.view, 'resize')
          if positions_list.length > 0
            if positions_list.length > 1
              map_instance.view.setCenter(bounds.getCenter())
              map_instance.view.fitBounds(bounds)
            else
              map_instance.view.setCenter(positions_list[0])
          else
            authority_position = get_default_position()
            authority_position = new google.maps.LatLng(authority_position.lat, authority_position.lng)
            map_instance.view.setCenter(authority_position)
    , 300)

#--------------------------------------------------------------------------------
# LOCATIONS FUNCTIONS
#--------------------------------------------------------------------------------

# Load all locations
window.load_locations = () ->
  geocodes_list = get_geocodes();
  $(".location-selector").each ->
    data = [];
    current_selector = $( this )
    target = current_selector.attr('data-target')
    current_selector.find("option").each ->
      current_option = $( this )
      id          = current_option.val();
      name        = current_option.text();
      address     = current_option.attr("data-location-address");
      synonyms    = current_option.attr("data-synonyms");
      latitude    = current_option.attr("data-location-latitude");
      longitude   = current_option.attr("data-location-longitude");
      data_item   = {
        value: id,
        label: "#{name} #{synonyms}",
        name: name,
        synonyms: synonyms,
      }
      data.push(data_item)
      if(latitude && longitude)
        geocodes_list[name] || (geocodes_list[name] = {lat: latitude, lng: longitude});
    current_inputs = $(".location-name[data-target=#{target}]")
    if( current_inputs.length > 0 )
      for current_input in current_inputs
        current_input = $(current_input)
        current_input.autocomplete({
          minLength: 0,
          source: data,
          select: ( event, ui ) ->
            current_item = $(this)
            current_item.val(ui.item.name)
            select_advanced_location(current_item)
            return false;
        }).autocomplete( "instance" )._renderItem = ( ul, item ) ->
          if item.synonyms != undefined
            syns = item.synonyms.replace(/\n/g, ", ")
            syns = "<div class='autocomplete-synonyms'>#{syns}</div>"
          else
            syns = ''

          return $( "<li><div class='autocomplete-item'><div class='autocomplete-label'>#{item.name}</div>#{syns}</div></li>" ).appendTo( ul );
    current_inputs = $(".location-street-name[data-target=#{target}]")
    if( current_inputs.length > 0 )
      for current_input in current_inputs
        current_input = $(current_input)
        current_input.autocomplete({
          minLength: 0,
          source: data,
          select: ( event, ui ) ->
            current_item = $(this)
            current_item.val(ui.item.name)
            select_simple_location(current_item)
            return false;
        }).autocomplete( "instance" )._renderItem = ( ul, item ) ->
          if item.synonyms != undefined
            syns = item.synonyms.replace(/\n/g, ", ")
            syns = "<div class='autocomplete-synonyms'>#{syns}</div>"
          else
            syns = ''
          return $( "<li><div class='autocomplete-item'><div class='autocomplete-label'>#{item.name}</div>#{syns}</div></li>" ).appendTo( ul );

# Select advanced location & manage form
select_advanced_location = (input) ->
  current_label = input.val()
  current_target = input.attr('data-target')
  current_select = $(".location-selector[data-target=#{current_target}]")
  current_value = null
  current_information = "That location's not in our database. Please select 'NOT IN LIST - FIND ON MAP' from the " +
    "dropdown below"
  current_position = get_default_position()

  $(".location-section-suggestions[data-target=#{current_target}] select").val("")
  $(".location-section-suggestions[data-target=#{current_target}] input").val("")
  $(".location-section-new[data-target=#{current_target}] select").val("")
  $(".location-section-new[data-target=#{current_target}] input").val("")
  select_advanced_suggestion($(".location-section-suggestions[data-target=#{current_target}] select"))
  # Set the value
  if( current_select.length > 0 )
    selected_option = null
    current_select.find( "option" ).each ->
      current_option = $(this)
      selected_option = current_option if( current_option.text() == current_label )
    current_text            = selected_option && selected_option.text()
    current_value           = selected_option && selected_option.val()
    current_type            = selected_option && selected_option.attr('data-kind')
    current_is_third_party  = selected_option && selected_option.attr('data-location-is-third-party')
    current_url             = selected_option && selected_option.attr('data-third-party-url')

    if (current_type && (current_type.toLowerCase() == "third party"))
      if window.current_app == "eventapp"
        current_information = "To apply for an event at #{current_text} you need to contact the venue directly at <a href='#{current_url}' target='_blank'>#{current_url}</a>"
      else
        current_information = "To apply to film at #{current_text} you need to contact the venue directly at <a href='#{current_url}' target='_blank'>#{current_url}</a>"
      current_photo_url = null
    else
      current_information = ( selected_option.attr("data-location-information") || "" ) if selected_option
      current_photo_url =  selected_option.attr("data-location-photo-url") if selected_option
    current_select.val(current_value)
    current_select.trigger("change")
  # Show & hide components
  is_empty_value = ( !current_value ) || ( current_value.length == 0 )
  # Popular locations component
  $(".location-section-popular[data-target=#{current_target}]").toggle(!current_label)
  $(".location-section-map[data-target=#{current_target}]").toggle(!is_empty_value && current_is_third_party != 'true')
  refresh_maps([current_target])
  # Suggestions component
  tolerance = 45
  if is_empty_value
    index = 0
    $(".location-section-suggestions[data-target=#{current_target}] option").each ->
      current = $(this)
      if (++index) > 2
        synonyms = current.attr('data-synonyms')
        valid = difference( current_label, current.text() ) < tolerance
        if !valid && synonyms
          for synonym in synonyms.split(',')
            valid ||= difference( current_label, synonym ) < tolerance
            break if valid
        current.toggle(valid)
      else
        current.toggle(true)
  $(".location-section-suggestions[data-target=#{current_target}]").toggle(is_empty_value)
  # Help box component
  current_help_box = $(".box-helper[data-target=#{current_target}]")
  if(current_help_box.length > 0)
    if current_photo_url
      current_help_box.html("<div class='row'><div class='col-xs-9 col-xs-offset-6'>#{current_information}</div><div class='col-xs-9'><img src='#{current_photo_url}' class='img-responsive'></div></div>")
    else
      current_help_box.html(current_information)
    current_help_box.toggle(true)
  # Raw component
  current_raw = $(".location-raw-value[data-target='#{current_target}']")
  if(current_raw.length > 0)
    async_get_position(current_label, selected_option, (position)->
      current_raw.val(JSON.stringify({ '0': position }));
      refresh_maps([current_target]);
    );
  # Approbation button
  approbation_area = $(".location-section-approbation[data-target=#{current_target}]")
  approbation_button = if (approbation_area.length > 0) then approbation_area.find('.approbation-button') else null
  if(approbation_button)
    if !is_empty_value
      location_name = selected_option.text()
      location_is_public = selected_option.attr('data-location-is-public')
      if location_is_public == "true"
        approbation_area.toggle(false)
      else
        data_confirm = "Do you really want to add the location '#{location_name}' to location database ?"
        approbation_button.html( selected_option.attr('data-location-approbation-label') )
        approbation_button.attr( "href", selected_option.attr('data-location-approbation-url') )
        approbation_button.attr( "data-confirm", data_confirm )
        approbation_area.toggle(true)
    else
      approbation_button.html( "" )
      approbation_button.attr( "href", "" )
      approbation_button.attr( "data-confirm", "" )
      approbation_area.toggle(false)



# Select advanced location & manage form
select_advanced_suggestion = (input) ->
  current_value     = input.val()
  current_target    = input.attr('data-target')
  current_selected  = input.find(":selected")
  not_in_this_list  = ( current_value == 'NOT IN LIST - FIND ON MAP' )
  new_section = $(".location-section-new[data-target=#{current_target}]")
  new_section.toggle(not_in_this_list)
  search_location(new_section) if not_in_this_list
  if(current_selected && current_value && !not_in_this_list)
    name_input = $(".location-name[data-target=#{current_target}]")
    name_input.val(current_selected.text())
    select_advanced_location(name_input)

# Search location with data on form
search_location = (formarea) ->
  if(formarea)
    current_target = formarea.attr("data-target")
    $(".location-section-map[data-target=#{current_target}]").toggle(true)
    name_input = $(".location-section-main[data-target=#{current_target}] .location-name")
    address = []
    address.push(name_input.val()) if name_input.length > 0 && name_input.val()
    formarea.find("input[type=text]").each ->
      local_value = $(this).val()
      address.push(local_value) if local_value
    address.unshift(window.current_city_area)  if window.current_city_area
    address.unshift(window.current_authority)  if window.current_authority
    address.unshift(window.current_country)    if window.current_country
    window.geocoder = new google.maps.Geocoder() if !window.geocoder
    window.geocoder.geocode( { 'address': address.join(", ") }, (results, status)->
      if status == "OK"
        set_found_location(current_target, results)
        set_information_location(formarea, results)
      else
        address.pop()
        window.geocoder.geocode( { 'address': address.join(", ") }, (results, status)->
          if status == "OK"
            set_found_location(current_target, results)
            set_information_location(formarea, results)
          else
            refresh_maps([current_target])
        )
    )


# get a item location
get_information_location = (results, field) ->
  result = results[0].address_components.find((component) ->
    component.types[0] == field
  )
  if typeof result != 'undefined'
    name =  result.long_name
    if name.length > 0
      name
    else
      name = ''
  else
    name = ''

merge_address_location = (number, address) ->
  if (typeof number != 'undefined' && typeof address != 'undefined')
    "#{number} #{address}"
  else
    if (typeof number != 'undefined')
      "#{number}"
    else
      "#{address}"

set_item_location = (target, results, tag, field, extra_params=[]) ->
  tag = target.find(tag)
  if extra_params.length <= 0
    field = get_information_location(results, field)
    tag.val(field)
  else
    field = get_information_location(results, field)
    extra_params.forEach (current_option) ->
      route = get_information_location(results, current_option)
      address_full = merge_address_location(field, route)
      tag.val(address_full)


# set the field on the form
set_information_location = (input, results) ->
  set_item_location(input, results, '.street', 'street_number', ['route'])
  set_item_location(input, results, '.city', 'locality')
  set_item_location(input, results, '.postcode', 'postal_code')


# Set found location
set_found_location = (current_target, results) ->

  current_map = window.maps[current_target]
  current_marker = current_map.markers['0'] if (current_map)
  if (current_marker)
    current_marker.setPosition(results[0].geometry.location)
  else
    current_marker = new google.maps.Marker({
      position: results[0].geometry.location,
      map: current_map.view,
      label: '0',
      draggable: true
    })
    google.maps.event.addListener(current_marker, "mouseup", (e) ->
      refresh_markers([ current_target ])
    )
  window.maps[current_target].markers['0'] = current_marker
  refresh_markers([current_target])

# Calculate the similitude of two strings
window.similitude = (text1, text2) ->
  100 - difference( text1, text2 )

# Calculate the difference of two strings
window.difference = ( text1, text2 ) ->
  result = 0
  if( text1 != text2 )
    map = {}
    length = 0
    if text1 && text1.length > 0
      length += text1.length
      for letter in text1
        map[letter] = if !map[letter] then 1 else ( map[letter] + 1 )
    if text2 && text2.length > 0
      length += text2.length
      for letter in text2
        map[letter] = if !map[letter] then -1 else ( map[letter] - 1 )
    for letter, count of map
      result += if count < 0 then ( - count ) else count
    result = 100 * result / length
  result

add_new_road = (button) ->
  current_area = button.closest('.street-area')
  road_items = current_area.find('.street-row')
  count = road_items.length + 1
  new_road_item = road_items.first().clone()
  label = new_road_item.find('label')
  label.text(label.text().replace("1", "#{count}"))
  input = new_road_item.find('input')
  input.attr("id", "#{input.attr("id")}#{count}" )
  input.attr("name", "#{input.attr("name")}#{count}" )
  input.attr("data-ref", "#{count}" )
  input.val( "" )
  new_road_item.insertBefore(button)

select_simple_location = (input) ->
  current_label = input.val()
  ref = input.attr('data-ref')
  current_id = input.attr('data-target')
  current_select = $(".location-selector[data-target=#{current_id}]")
  current_raw = $(".location-raw-value[data-target='#{current_id}']")
  target = $(".location-raw-value[data-target=#{current_id}]")
  if( current_select.length > 0 )
    selected_option = null
    current_select.find( "option" ).each ->
      current_option = $(this)
      selected_option = current_option if( current_option.text() == current_label )

  async_get_position(current_label, selected_option, (position)->
    try
      data = target.val()
      data = JSON.parse(data)
    catch e
      data = {}
    data[ref] = position
    data[ref]['label'] = current_label
    current_raw.val( JSON.stringify( data ) ) if (current_raw.length > 0)
    refresh_maps([current_id])
  );

popup_notice = (current)->
  text = current.attr('data-popup-notice')
  if text
    location_types = null
    modal_text = null
    value = null
    target = current
    data = obtain_data(target)
    value = data['value']
    location_data = data['location_data']
    location_type = data['location_types']
    html_from_text = $($.parseHTML("<div>#{text}</div>")).find("[data-show-when]")
    if html_from_text.length > 0
      html_from_text.each () ->
        current_text = $(this)
        expression = current_text.attr("data-show-when")
        try
          modal_text = current_text.html() if eval(expression)
        catch e
          null
    else
      modal_text = text

    if modal_text
      rollback_action = null
      current_type = current.attr("type")
      current_type = "radio" if current.hasClass('radio-area')
      switch current_type
        when "checkbox"
          rollback_action = "$('##{current.attr('id')}').prop( 'checked', $('##{current.attr('id')}').get(0).old_value )"
        when "radio"
          rollback_action = "$('##{current.attr('id')} :radio[value=\"#{$('#'+current.attr('id')).get(0).old_value}\"]').prop('checked', true )"
        else
          rollback_action = "$('##{current.attr('id')}').val( $('##{current.attr('id')}').get(0).old_value )"
      show_shared_modal("Please Note", modal_text, "Continue", "Go back", null, rollback_action, rollback_action)

# After page loaded
$ ->
  load_locations()

  window.maps ||= {}
  init_map_answer($('.map-answer-container'))

  $(document).on 'click', '[data-toggle]', () ->
    refresh_all_maps()

  $(document).on 'click', '[href]', () ->
    refresh_all_maps()

  $(document).on 'keypress', '.location-street-name', (e) ->
    if( e.charCode == 13 )
      e.preventDefault()
      select_simple_location($(this))

  $(document).on 'blur', '.location-street-name', (e) ->
    select_simple_location($(this))

  $(document).on 'keypress', '.location-name', (e) ->
    if( e.charCode == 13 )
      e.preventDefault()
      select_advanced_location($(this))

  $(document).on 'click', '[data-location-name][data-target]', (e) ->
    input = $(".location-name[data-target=#{$(this).attr('data-target')}]")
    input.val($(this).attr('data-location-name'))
    select_advanced_location(input)

  $(document).on 'change', '.location-name', (e) ->
    select_advanced_location($(this))

  $(document).on 'change', '.location-suggestion', (e) ->
    select_advanced_suggestion($(this))

  $(document).on 'click', '.add_new_street_road', (e) ->
    e.preventDefault()
    add_new_road($(this))


  $(document).on 'keypress', '.location-section-new input[type=text]', (e) ->
    if( e.charCode == 13 )
      e.preventDefault()
      current = $(this)
      formarea = current.closest('.location-section-new')
      search_location(formarea)

  $(document).on 'change', '.location-section-new input[type=text]', (e) ->
    current = $(this)
    formarea = current.closest('.location-section-new')
    search_location(formarea)

  $(document).on 'focus', '[data-popup-notice]', (e) ->
    current = $(this)
    current_type = current.attr("type")
    current_type = "radio" if current.hasClass('radio-area')
    switch current_type
      when "checkbox"
        this.old_value = current.prop("checked")
      when "radio"
        this.old_value = current.find('input:radio:checked').val()
      else
        this.old_value = current.val()

  $(document).on 'change', '[data-popup-notice]', (e) ->
    popup_notice($(this))

  $(document).on 'keypress', '[data-popup-notice]', (e) ->
    if( e.charCode == 13 )
      e.preventDefault()
      current = $(this)
      current.blur()

  $(document).on 'click', '.actions-area .action-button[data-action]', (e)->
    current = $(this)
    action_name = current.attr('data-action')
    form = current.closest('form')
    if form.length > 0
      target = if action_name.toLowerCase() == "submit" then "_self" else "_blank"
      form.attr("target", target)
      form.find("[name=commit]").val(action_name)
      form.submit()
